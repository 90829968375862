import {observer} from 'mobx-react';
import {useState} from 'react';
import {Layout} from '../../styles/layout';
import {Text} from '../../styles/text';
import {Button} from '../../styles/button';
import {
    EyeImgIcon,
    Table,
    TableBody,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import EyeIcon from '../../assets/icons/eye.svg';
import {primaryColor} from '../../utils/variables';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import ModalView from '../../components/modal/modal.view';
import {ClientsModel} from './clients.model';

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface ClientsTableMobileViewProps {
    loading: boolean;
    clients: ClientsModel[] | undefined;
    handleAccountClick: (client: ClientsModel) => void;
    handleClientContactsClick: (client: ClientsModel) => void;
    handleEditClick: (client: ClientsModel) => void;
    setConfirmDeleteModal: ({
                                isModalOpen,
                                id,
                                name,
                            }: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
}

const ClientsTableMobileView = observer(
    ({
         loading,
         clients,
         handleClientContactsClick,
         handleAccountClick,
         handleEditClick,
         setConfirmDeleteModal,
         tableHeaders
     }: ClientsTableMobileViewProps) => {
        const [modal, setModal] = useState(false);
        const [initialValue, setInitialValue] = useState<ClientsModel | null>(null);

        const handleRowClick = (client: ClientsModel) => {
            setModal(true);
            setInitialValue(client);
        };

        const handleCloseModal = () => {
            setModal(false);
            setInitialValue(null);
        };

        const renderModalItems = (client: ClientsModel) => (
            <Layout vertical between={10}>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('full_name')}</Text>
                    <Text>{client.full_name}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('phone')}</Text>
                    <Text>{client.phone}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('address')}</Text>
                    <Text>{client.address}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('inn')}</Text>
                    <Text>{client.inn}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('passport_number')}</Text>
                    <Text>{client.passport_series + client.passport_number}</Text>
                </Layout>
                <Layout hAlign={'center'} between={5}>
                    <Button onClick={() => handleClientContactsClick(client)}>
                        Контакты
                    </Button>
                    <Button onClick={() => handleAccountClick(client)}>Счета</Button>
                    <Button onClick={() => handleEditClick(client)}>Изменить</Button>
                    <Button
                        background={'rgb(146,4,4)'}
                        onClick={() =>
                            setConfirmDeleteModal({
                                isModalOpen: true,
                                id: client.id,
                                name: client.name,
                            })
                        }
                    >
                        Удалить
                    </Button>
                </Layout>
            </Layout>
        );

        const renderedItems = () => (
            <>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{tableHeaders('full_name')}</THeadCell>
                            <THeadCell>{tableHeaders('phone')}</THeadCell>
                            <THeadCell>{tableHeaders('address')}</THeadCell>
                            <THeadCell></THeadCell>
                        </TRow>
                    </TableHead>
                    {!loading ? (
                        <TableBody>
                            {clients?.length ? (
                                clients?.map((client, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{client.full_name}</TCell>
                                        <TCell>{client.phone}</TCell>
                                        <TCell>{client.address}</TCell>
                                        <TCell relative>
                                            <EyeImgIcon
                                                src={EyeIcon}
                                                onClick={() => handleRowClick(client)}
                                                height="18px"
                                                color={primaryColor}
                                            />
                                        </TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={4}/>
                            )}
                        </TableBody>
                    ) : (
                        <TableSkeleton columns={4}/>
                    )}
                </Table>
                {modal && (
                    <ModalView
                        title={`Данные клиента ${initialValue?.name}`}
                        onClose={handleCloseModal}
                    >
                        {renderModalItems(initialValue!)}
                    </ModalView>
                )}
            </>
        );

        return <Layout>{renderedItems()}</Layout>;
    }
);

export default ClientsTableMobileView;
