import {observer} from "mobx-react";
import {PageView} from "../../styles/page";
import AppStore from "../../app.store";
import {TableContainer, TableContainerMobile} from "../../styles/table";
import StatusesTableDesktopView from "./statuses.table.desktop.view";
import StatusesTableMobileView from "./statuses.table.mobile.view";
import StatusesStore from "./statuses.store";
import {useEffect} from "react";
import {StatusModel} from "./statuses.model";
import DictionaryStore from "../../utils/dictionary/dictionary.store";

interface StatusesReferencesViewProps {
    initialValue: StatusModel;
}

const StatusesReferencesView = observer(({initialValue}: StatusesReferencesViewProps) => {
    const {isNavOpen} = AppStore;
    const {statusesAfterReq} = StatusesStore;
    const {destroyDictionary, getTableHeaders, dictionaryListReq} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('STATUSES', 'TABLE HEADERS', controller.signal)
        void statusesAfterReq(initialValue.id);
        return () => controller.abort();
    }, [destroyDictionary, dictionaryListReq, statusesAfterReq]);

    return (
        <PageView isNavOpen={isNavOpen}>
            <TableContainer>
                <StatusesTableDesktopView
                    handleReferencesClick={() => {}}
                    handleEditClick={() => {}}
                    setConfirmDeleteModal={() => {}}
                    handleContext={() => {}}
                    anchorEl={null}
                    reference={true}
                    tableHeaders={getTableHeaders}
                />
            </TableContainer>
            <TableContainerMobile>
                <StatusesTableMobileView
                    handleReferencesClick={() => {}}
                    handleEditClick={() => {}}
                    setConfirmDeleteModal={() => {}}
                    reference={true}
                    tableHeaders={getTableHeaders}
                />
            </TableContainerMobile>
        </PageView>
    );
});

export default StatusesReferencesView;