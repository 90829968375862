import {Headline} from '../../styles/headline';
import {primaryColor} from '../../utils/variables';
import {observer} from 'mobx-react';
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import AppStore from '../../app.store';
import {Page} from '../../styles/page';
import {useEffect, useState} from 'react';
import {AddButton, TableHeader} from '../../styles/table-header';
import StockStore from './stock.store';
import {Image} from '../../styles/image';
import Modal from '../../components/modal/modal.view';
import ConfirmDelete, {
    DeleteItem,
} from '../../components/confirm/confirm.delete';
import {Filters} from '../../styles/filters';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import GoodsModal from './stock.modal/stock.modal.view';
import ContextMenuIcon from '../../assets/icons/context-menu.svg';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import AccountModalView from '../../components/modal/account.modal.view';
import {StocksFilterModel} from './stocks.model';
import TablePaginationView from '../../utils/table.pagination.view';
import StocksTableMobileView from './stocks.mobile.view';
import StocksFiltersView from './stocks.filter.view';
import {Button, FilterButton} from '../../styles/button';
import ProductCategoriesStore from '../product_categories/product_categories.store';
import BrandsStore from '../brands/brands.store';
import BusinessUnitsStore from '../business_units/business_units.store';
import {Layout} from '../../styles/layout';
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {TBusinessStocksGetResponse} from "./stock.types";
import StocksExpiryDateView from "./stocks.expiry.date.view";
import StockModalStore from "./stock.modal/stock.modal.store";

export const Stock = observer(() => {
    const {isNavOpen} = AppStore;
    const [modalPurpose, setModalPurpose] = useState<'create' | 'hidden'>(
        'hidden'
    );
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<
        DeleteItem & { business_unit_id?: number }
    >({
        isModalOpen: false,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const [expiryModal, setExpiryModal] = useState(false);
    const [initialValue, setInitialValue] = useState<{
        name: '';
        business_unit: '';
        goods_id: 0;
        business_unit_id: 0;
    } | null>(null)
    const {businessUnits, setBusinessUnitFilter} = BusinessUnitsStore;

    const handleDelete = async () => {
        await StockStore.deleteProduct(
            String(confirmDeleteModal.id!),
            String(confirmDeleteModal.business_unit_id!)
        );
        setConfirmDeleteModal({isModalOpen: false});
    };
    const {destroyExpiryDates} = StockStore;
    const {destroy} = StockModalStore;

    const {productCategories, productCategoriesPageInit} =
        ProductCategoriesStore;
    const {brands, brandsPageInit} = BrandsStore;
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('STOCKS', 'TABLE HEADERS', controller.signal);
        void StockStore.getGoods(controller.signal);
        void StockStore.getMeasurements(controller.signal);
        void productCategoriesPageInit(controller.signal);
        void brandsPageInit(true, controller.signal);
        setBusinessUnitFilter({page: 1, page_limit: 9999});

        return () => controller.abort();
    }, [
        destroyDictionary,
        dictionaryListReq,
        StockStore.getGoods,
        StockStore.getMeasurements,
        productCategoriesPageInit,
        brandsPageInit
    ]);

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleCloseExpiryModal = () => {
        destroyExpiryDates();
        setExpiryModal(false);
    }

    const handleExpiryDatesClick = (stock: any) => {
        setInitialValue(stock);
        setExpiryModal(true);
    }

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            StockStore.setStocksFilter({page: index + 1} as StocksFilterModel);
            return;
        }
        StockStore.setStocksFilter({
            [objectKey]: options[selectedIndex].value,
        } as StocksFilterModel);
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && StockStore.filter.search !== '') {
            StockStore.setStocksFilter({search: ''} as StocksFilterModel);
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            StockStore.setStocksFilter({
                search: e.target.value,
            } as StocksFilterModel);
        }
    };

    const prevPage = () => {
        if (StockStore.filter.page > 1) {
            StockStore.setStocksFilter({
                page: StockStore.filter.page - 1,
            } as StocksFilterModel);
        }
    };

    const nextPage = () => {
        if (StockStore.filter.page < StockStore.filter.pages) {
            StockStore.setStocksFilter({
                page: StockStore.filter.page + 1,
            } as StocksFilterModel);
        }
    };

    const handleClearFilter = () => {
        StockStore.clearFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = () => {
        setFilterModal(false);
    };

    const handleCloseCreateModal = () => {
        setModalPurpose('hidden');
        destroy();
    }

    const pageLimits = [25, 100, 500];

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <>
                <Headline color={primaryColor}>{getPageTitle('STOCKS')}</Headline>
                <TableHeader>
                    <Filters hAlign="space-between" wrapped between={10}>
                        <StocksFiltersView
                            handleKeyDown={handleKeyDown}
                            handleFilterChange={handleFilterChange}
                            cancelSearch={cancelSearch}
                            measureUnits={StockStore.measurements}
                            categories={productCategories!}
                            brands={brands}
                            businessUnits={businessUnits}
                            inModal={false}
                        />
                    </Filters>
                    <FilterButton onClick={() => setFilterModal(true)}>
                        Фильтры
                    </FilterButton>
                    <AddButton
                        type="button"
                        id="add-role"
                        onClick={() => setModalPurpose('create')}
                    >
                        Создать
                    </AddButton>
                </TableHeader>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>{getTableHeaders('name')}</THeadCell>
                                <THeadCell>{getTableHeaders('business_unit')}</THeadCell>
                                <THeadCell>{getTableHeaders('category')}</THeadCell>
                                <THeadCell>{getTableHeaders('brand')}</THeadCell>
                                <THeadCell>{getTableHeaders('description')}</THeadCell>
                                <THeadCell>{getTableHeaders('measure_unit')}</THeadCell>
                                <THeadCell>{getTableHeaders('quantity')}</THeadCell>
                                <THeadCell>{getTableHeaders('set_price')}</THeadCell>
                                <THeadCell></THeadCell>
                            </TRow>
                        </TableHead>
                        {!StockStore.loading ? (
                            <TableBody>
                                {StockStore.stocks!.length ? (
                                    StockStore.stocks?.map((product, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{product.name}</TCell>
                                            <TCell>{product.business_unit}</TCell>
                                            <TCell>{product.category}</TCell>
                                            <TCell>{product.brand}</TCell>
                                            <TCell>{product.description}</TCell>
                                            <TCell>{product.measure_unit}</TCell>
                                            <TCell>{product.quantity}</TCell>
                                            <TCell>{product.set_price}</TCell>
                                            <TCell relative>
                                                <Image
                                                    src={ContextMenuIcon}
                                                    height="18px"
                                                    onClick={handleContext}
                                                />
                                                <ContextMenu anchorEl={anchorEl}>
                                                    {product.has_expiry && (
                                                        <ContextMenuItem
                                                            onClick={() => handleExpiryDatesClick(product)}
                                                        >
                                                            Подробнее
                                                        </ContextMenuItem>
                                                    )}
                                                    <ContextMenuItem
                                                        onClick={() =>
                                                            setConfirmDeleteModal({
                                                                isModalOpen: true,
                                                                id: product.goods_id,
                                                                business_unit_id: product.business_unit_id,
                                                                name: product.name,
                                                            })
                                                        }
                                                    >
                                                        Удалить
                                                    </ContextMenuItem>
                                                </ContextMenu>
                                            </TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={9}/>
                                )}
                            </TableBody>
                        ) : (
                            <TableSkeleton columns={9}/>
                        )}
                    </Table>
                </TableContainer>
                <TableContainerMobile>
                    <StocksTableMobileView
                        loading={StockStore.loading}
                        stocks={StockStore.stocks}
                        setConfirmDeleteModal={setConfirmDeleteModal}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>
                <TablePaginationView
                    prevPage={prevPage}
                    nextPage={nextPage}
                    pages={StockStore.filter.pages}
                    currentPage={StockStore.filter.page!}
                    pageLimits={pageLimits}
                    handleFilterChange={handleFilterChange}
                />
            </>
            {filterModal && (
                <Modal title={'Фильтры'} onClose={() => setFilterModal(false)}>
                    <Layout vertical between={10}>
                        <StocksFiltersView
                            handleKeyDown={handleKeyDown}
                            handleFilterChange={handleFilterChange}
                            cancelSearch={cancelSearch}
                            measureUnits={StockStore.measurements}
                            categories={productCategories!}
                            brands={brands}
                            businessUnits={businessUnits}
                            inModal={true}
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={handleConfirmFilter}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
            {modalPurpose !== 'hidden' && (
                <Modal
                    title={'Привязать товар к бизнес объекту'}
                    onClose={handleCloseCreateModal}
                >
                    <GoodsModal
                        purpose={modalPurpose}
                        closeModal={() => setModalPurpose('hidden')}
                    />
                </Modal>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить привязку к бизнес точке?"
                    onClose={() => {
                        setConfirmDeleteModal({isModalOpen: false});
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить товар из точки ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={StockStore.loading}
                    />
                </Modal>
            )}
            {expiryModal && (
                <Modal
                    title='Подробнее'
                    onClose={handleCloseExpiryModal}
                >
                    <StocksExpiryDateView initialValue={initialValue}/>
                </Modal>
            )}
        </Page>
    );
});
