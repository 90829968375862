import axios from 'axios';
import {makeAutoObservable} from 'mobx';
import { api } from '../../../api/endpoints';
import AppStore from '../../../app.store';
import { UsersModel } from '../users.model';
import { toast } from 'react-toastify';

const initialValues = {
    active: true,
    email: '',
    login: '',
    name: '',
    password: '',
    patronymic: '',
    phone: '',
    surname: '',
    business_unit_id: 0,
    role_id: 0,
    id: 0,
    business_unit: '',
    full_name: '',
    bonus_amount: 0,
    role: '',
    salary: 0,
    default_status_id: 0,
};

class UsersCreateEditStore {
    private businessUnits?: any[];
    private roles?: any[];

    public form: UsersModel = initialValues;
    public error = '';
    public message = '';
    public isLoading = false;
    
    constructor() {
        makeAutoObservable(this);
    }

    public init = async (initialValue: UsersModel | null) => {
        await this.businessUnitsReq();
        await this.rolesReq();
        if (initialValue) {
            this.form = initialValue;
            this.form.business_unit_id = this.businessUnitOptions?.find((item) => this.form.business_unit === item.label)?.value;
            this.form.role_id = this.roleOptions?.find((item) => this.form.role === item.label)?.value;
        } else {
            this.form = initialValues;
        }
    }

    private businessUnitsReq = async () => {
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            const filterUrl = `?page=1&order_by=name&order_descending=false`;
            const response = await axios.get(api.get_business_units + filterUrl, {headers});
            this.businessUnits = response.data.business_units;
        } catch (err) {
        }
    }

    private rolesReq = async () => {
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            const filterUrl = `?page=1&order_by=name&order_descending=false`;
            const response = await axios.get(api.get_roles + filterUrl, {headers});
            this.roles = response.data.roles;
        } catch (err) {
            console.error(err);
        }
    }

    public createOrEditUser = async (edit?: boolean) => {
        this.isLoading = true;
        const payload = {
            id: this.form.id,
            active: this.form.active,
            business_unit_id: this.form.business_unit_id,
            email: this.form.email,
            login: this.form.login,
            name: this.form.name,
            password: this.form.password,
            patronymic: this.form.patronymic,
            phone: this.form.phone,
            role_id: this.form.role_id,
            surname: this.form.surname,
            salary: Number(this.form.salary)
        }
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            let res;
            if (edit) {
                res = await axios.put(api.user, payload, {headers});
            } else {
                res = await axios.post(api.user, payload, {headers});
            }
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = res?.data.message;
            toast.success(this.message);

            return res;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.isLoading = false;
        }
    }

    public setField = (form: UsersModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
    }

    public get businessUnitOptions() {
        if (!this.businessUnits) return;
        return this.businessUnits.map((bu: any) => ({
            label: bu.name,
            value: bu.id
        }));
    }

    public get roleOptions() {
        if (!this.roles) return;
        return this.roles.map((role: any) => ({
            label: role.name,
            value: role.id
        }));
    }
}

export default new UsersCreateEditStore();