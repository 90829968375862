import {Headline} from '../../styles/headline';
import {primaryColor} from '../../utils/variables';
import {observer} from 'mobx-react';
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import AppStore from '../../app.store';
import {Page} from '../../styles/page';
import {useEffect, useState} from 'react';
import {
    AddButton,
    TableHeader,
} from '../../styles/table-header';
import ProductsStore from './products.store';
import ProductBrandsStore from './product.brands.store';
import ProductCategoriesStore from './product.categories.store';
import ProductTypeStore from './product.type.store';
import {Layout} from '../../styles/layout';
import {Image} from '../../styles/image';
import ContextMenuIcon from '../../assets/icons/context-menu.svg';
import Modal from '../../components/modal/modal.view';
import ProductsCreateEditView from './products.modal/products.create.edit.view';
import {Filters} from '../../styles/filters';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import ConfirmDelete, {
    DeleteItem,
} from '../../components/confirm/confirm.delete';
import {ProductModel} from './products.model';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import TablePaginationView from '../../utils/table.pagination.view';
import {Button, FilterButton} from '../../styles/button';
import ProductsFilterView from './products.filter.view';
import ProductsMobileView from './products.mobile.view';
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import ProductsCreateView from "./products.create.modal/products.create.view";
import AccountModalView from "../../components/modal/account.modal.view";
import ProductsImportExcelStore from "./products.create.modal/products.create.store";

const ProductsView = observer(() => {
    const {isNavOpen, businessSettings} = AppStore;
    const {
        products,
        productsFilter,
        productsInit,
        setProductsFilter,
        deleteProduct,
        loading,
        clearFilter,
    } = ProductsStore;
    const {productTypes, productTypesInit} = ProductTypeStore;
    const {productCategories, productCategoriesInit} = ProductCategoriesStore;
    const {productBrands, productBrandsInit} = ProductBrandsStore;
    const [modal, setModal] = useState(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });
    const [initialValue, setInitialValue] = useState<ProductModel | null>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const [importExcelModal, setImportExcelModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;
    const {destroy} = ProductsImportExcelStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();

        void dictionaryListReq('PRODUCTS', 'TABLE HEADERS', controller.signal);
        productsInit(controller.signal);
        productTypesInit(controller.signal);
        productCategoriesInit(controller.signal);
        productBrandsInit(controller.signal);

        return () => controller.abort();
    }, [
        destroyDictionary,
        dictionaryListReq,
        productBrandsInit,
        productCategoriesInit,
        productTypesInit,
        productsInit,
    ]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setProductsFilter({page: index + 1});
            return;
        }
        setProductsFilter({[objectKey]: options[selectedIndex].value});
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && productsFilter.search !== '') {
            setProductsFilter({search: ''});
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setProductsFilter({search: e.target.value});
        }
    };

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleEditClick = (product: ProductModel) => {
        setModal(true);
        setInitialValue(product);
    };

    const handleDelete = async () => {
        await deleteProduct(confirmDeleteModal.id!);
        setConfirmDeleteModal({isModalOpen: false});
    };

    const prevPage = () => {
        if (productsFilter.page > 1) {
            setProductsFilter({page: productsFilter.page - 1});
        }
    };

    const nextPage = () => {
        if (productsFilter.page < productsFilter.pages) {
            setProductsFilter({page: productsFilter.page + 1});
        }
    };

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = () => {
        setFilterModal(false);
    };

    const handleCloseImportExcelModal = () => {
        destroy();
        setImportExcelModal(false);
    }

    const handleColumnNumbers = () => {
        let columnNumber: number = 9

        if (businessSettings?.employee_bonus) {
            columnNumber++
        }

        if (businessSettings?.product_commissions) {
            columnNumber++
        }

        return columnNumber
    }

    const getCommissionTypeName = (commission_type_id: number) => {
        switch (commission_type_id) {
            case 1: return 'Процент от суммы';
            case 2: return 'Фиксированная сумма';
            case 3: return 'Фиксированная сумма -> Лимит -> Процент от суммы';
            case 4: return 'Фиксированная сумма -> Лимит -> Фиксированная сумма';
        }
    }

    const pageLimits = [5, 10, 25, 100, 500];

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <>
                <Headline color={primaryColor}>{getPageTitle('PRODUCTS')}</Headline>
                <TableHeader>
                    <Layout hAlign={"flex-start"} vAlign={"center"}>
                        <Filters hAlign="space-between" wrapped between={10}>
                            <ProductsFilterView
                                handleKeyDown={handleKeyDown}
                                cancelSearch={cancelSearch}
                                handleFilterChange={handleFilterChange}
                                productCategories={productCategories}
                                productTypes={productTypes}
                                productBrands={productBrands}
                                inModal={false}
                            />
                        </Filters>
                        <FilterButton
                            id={'products-filter-button'}
                            onClick={() => setFilterModal(true)}
                        >
                            Фильтры
                        </FilterButton>
                    </Layout>
                    <Layout hAlign={"flex-end"} vAlign={"center"} between={10}>
                        <AddButton type="button" id="add-product" onClick={() => setModal(true)}>
                            Создать
                        </AddButton>
                        <AddButton type="button" id="import-excel" onClick={() => setImportExcelModal(true)}>
                            Создать несколько
                        </AddButton>
                    </Layout>
                </TableHeader>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>{getTableHeaders('name')}</THeadCell>
                                <THeadCell>{getTableHeaders('part_number')}</THeadCell>
                                <THeadCell>{getTableHeaders('type')}</THeadCell>
                                <THeadCell>{getTableHeaders('category')}</THeadCell>
                                <THeadCell>{getTableHeaders('brand')}</THeadCell>
                                <THeadCell>{getTableHeaders('cost')}</THeadCell>
                                <THeadCell>{getTableHeaders('profitability')}</THeadCell>
                                <THeadCell>{getTableHeaders('set_price')}</THeadCell>
                                {businessSettings?.employee_bonus && (
                                    <THeadCell>{getTableHeaders('bonus_percentage')}</THeadCell>
                                )}
                                <THeadCell></THeadCell>
                            </TRow>
                        </TableHead>
                        {!loading ? (
                            <TableBody>
                                {products!.length ? (
                                    products?.map((product, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{product.name}</TCell>
                                            <TCell>{product.part_number}</TCell>
                                            <TCell>{product.type}</TCell>
                                            <TCell>{product.category}</TCell>
                                            <TCell>{product.brand}</TCell>
                                            {product.type_id === 1 ? (
                                                <TCell>{product.cost}</TCell>
                                            ) : (
                                                <TCell></TCell>
                                            )}
                                            {product.type_id === 2 ? (
                                                <TCell>{product.profitability}</TCell>
                                            ) : (
                                                <TCell></TCell>
                                            )}
                                            <TCell>{product.set_price}</TCell>
                                            {businessSettings?.employee_bonus && (
                                                <TCell>{product.bonus_percentage}%</TCell>
                                            )}
                                            <TCell relative>
                                                <Image
                                                    src={ContextMenuIcon}
                                                    height="18px"
                                                    onClick={handleContext}
                                                />
                                                <ContextMenu anchorEl={anchorEl}>
                                                    <ContextMenuItem
                                                        onClick={() => handleEditClick(product)}
                                                    >
                                                        Изменить
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() =>
                                                            setConfirmDeleteModal({
                                                                isModalOpen: true,
                                                                id: product.id,
                                                                name: product.name,
                                                            })
                                                        }
                                                    >
                                                        Удалить
                                                    </ContextMenuItem>
                                                </ContextMenu>
                                            </TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={handleColumnNumbers()}/>
                                )}
                            </TableBody>
                        ) : (
                            <TableSkeleton
                                columns={handleColumnNumbers()}
                            />
                        )}
                    </Table>
                </TableContainer>
                <TableContainerMobile>
                    <ProductsMobileView
                        loading={loading}
                        products={products}
                        handleEditClick={handleEditClick}
                        setConfirmDeleteModal={setConfirmDeleteModal}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>
                <TablePaginationView
                    prevPage={prevPage}
                    nextPage={nextPage}
                    pages={productsFilter.pages}
                    currentPage={productsFilter.page}
                    pageLimits={pageLimits}
                    handleFilterChange={handleFilterChange}
                />
            </>
            {filterModal && (
                <Modal title={'Фильтры'} onClose={() => setFilterModal(false)}>
                    <Layout vertical between={10}>
                        <ProductsFilterView
                            handleKeyDown={handleKeyDown}
                            cancelSearch={cancelSearch}
                            handleFilterChange={handleFilterChange}
                            productCategories={productCategories}
                            productTypes={productTypes}
                            productBrands={productBrands}
                            inModal
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={handleConfirmFilter}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
            {modal && (
                <Modal
                    title={initialValue ? 'Изменить продукт' : 'Создать продукт'}
                    onClose={() => {
                        setModal(false);
                        setInitialValue(null);
                    }}
                >
                    <ProductsCreateEditView
                        initialValue={initialValue}
                        setModal={setModal}
                    />
                </Modal>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить продукт"
                    onClose={() => {
                        setConfirmDeleteModal({isModalOpen: false});
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить продукт ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={loading}
                    />
                </Modal>
            )}
            {importExcelModal && (
                <AccountModalView
                    title='Добавить продукт'
                    onClose={handleCloseImportExcelModal}
                >
                    <ProductsCreateView
                        setModal={setImportExcelModal}
                    />
                </AccountModalView>
            )}
        </Page>
    );
});

export default ProductsView;
