import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { api } from '../../../api/endpoints';
import AppStore from '../../../app.store';
import ProductTypeStore from '../product.type.store';
import ProductCategoriesStore from '../product.categories.store';
import ProductBrandsStore from '../product.brands.store';
import { toast } from 'react-toastify';
export interface ProductCreateForm {
  id: number;
  type_id: number;
  brand_id: number;
  category_id: number;
  name: string;
  description: string;
  part_number: string;
  cost: number;
  set_price: number;
  fixed_price: boolean;
  outflow: boolean;
  outflow_id: number;
  bonus_percentage: string;
  // todo remove
  fixed_commission_limit: number;
  fixed_commission_amount: number;
  //

  commission_type_id: number;
  commission: number;
  fixed_commission: number;
  // commission with limit
  commission_limit: number;
  commission_before_limit: number;
  commission_after_limit: number;

  has_expiry: boolean;
}

class ProductsCreateEditStore {
  private initialValues = {
    id: 0,
    type_id: 0,
    brand_id: 0,
    category_id: 0,
    name: '',
    description: '',
    part_number: '',
    cost: 0,
    set_price: 0,
    fixed_price: false,
    outflow: false,
    outflow_id: 0,
    bonus_percentage: '',
    // todo remove
    fixed_commission_limit: 0,
    fixed_commission_amount: 0,
    //

    commission_type_id: 0,
    commission: 0,
    fixed_commission: 0,
    commission_limit: 0,
    commission_before_limit: 0,
    commission_after_limit: 0,
    has_expiry: false,
  };

  public form: ProductCreateForm = this.initialValues;
  public error = '';
  public message = '';
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public init = (initialValues: any) => {
    if (initialValues) {
      this.form = initialValues;
      if (initialValues.outflow) {
        this.form.outflow_id = 2;
      } else {
        this.form.outflow_id = 1;
      }
    } else {
      this.form = this.initialValues;
    }
  };

  public createUpdateProduct = async (edit?: boolean) => {
    this.isLoading = true;
    try {
      if (this.form.outflow_id === 2) {
        this.form.outflow = true;
      } else {
        this.form.outflow = false;
      }

      const payload = {
        id: this.form.id,
        type_id: this.form.type_id,
        brand_id: this.form.brand_id,
        category_id: this.form.category_id,
        name: this.form.name,
        description: this.form.description,
        part_number: this.form.part_number,
        cost: Number(this.form.cost),
        set_price: Number(this.form.set_price),
        fixed_price: this.form.fixed_price,
        outflow: this.form.outflow,
        outflow_id: this.form.outflow_id,
        bonus_percentage: Number(this.form.bonus_percentage),
        //fixed_commission_limit: Number(this.form.fixed_commission_limit),
        //fixed_commission_amount: Number(this.form.fixed_commission_amount),
        commission_type_id: Number(this.form.commission_type_id),
        fixed_commission: Number(this.form.fixed_commission),
        commission: Number(this.form.commission),
        commission_limit: Number(this.form.commission_limit),
        commission_before_limit: Number(this.form.commission_before_limit),
        commission_after_limit: Number(this.form.commission_after_limit),
        has_expiry: this.form.has_expiry
      };

      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      let res: any;
      if (edit) {
        res = await axios.put(api.product, payload, { headers });
      } else {
        res = await axios.post(api.product, payload, { headers });
      }

      this.message = res.data.message;
      toast.success(this.message);

      return res;
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      this.isLoading = false;
    }
  };

  public setField = (form: ProductCreateForm) => {
    this.form = form;
  };

  public destroy = () => {
    this.form = this.initialValues;
  };

  public get productTypeOptions() {
    const { productTypes } = ProductTypeStore;

    if (!productTypes) {
      return [];
    }

    return productTypes.map((productType: any) => ({
      label: productType.name,
      value: productType.id,
    }));
  }

  public get productCategoriesOptions() {
    const { productCategories } = ProductCategoriesStore;

    if (!productCategories) {
      return [];
    }

    return productCategories.map((productType: any) => ({
      label: productType.name,
      value: productType.id,
    }));
  }

  public get productBrandsOptions() {
    const { productBrands } = ProductBrandsStore;

    if (!productBrands) {
      return [];
    }

    return productBrands.map((productType: any) => ({
      label: productType.name,
      value: productType.id,
    }));
  }

  public get productOutflowOptions() {
    const arr = [
      { label: 'Приход', value: 1 },
      { label: 'Расход', value: 2 },
    ];

    return arr;
  }

  public get productCommissionTypeOptions() {
    const arr = [
      { label: 'Процент от суммы', value: 1},
      { label: 'Фиксированная сумма', value: 2},
      { label: 'Фиксированная сумма -> Лимит -> Процент от суммы', value: 3},
      { label: 'Фиксированная сумма -> Лимит -> Фиксированная сумма', value: 4},
    ];

    return arr;
  }
}

export default new ProductsCreateEditStore();
