import { Headline } from "../../styles/headline";
import { primaryColor } from "../../utils/variables";
import { observer } from "mobx-react";
import {
    Table,
    TableBody,
    TableContainer, TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from "../../styles/table";
import AppStore from "../../app.store";
import { Page } from "../../styles/page";
import { useEffect, useState } from "react";
import {
    Search,
    SearchLayout,
    TableHeader,
} from "../../styles/table-header";
import { Image } from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";
import Modal from "../../components/modal/modal.view";
import { Filters } from "../../styles/filters";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import ProductsTransactionsStore from "./products_transactions.store";
import { ProductsTransactionsModalView } from "./products_transactions.modal/products_transactions.modal.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import TablePaginationView from "../../utils/table.pagination.view";
import {Button, FilterButton} from "../../styles/button";
import {Layout} from "../../styles/layout";
import ProductsTransactionTableMobileView from "./products_transactions.mobile.view";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import OperationView from "./create.operation.modal/operation.view";

export const ProductsTransactionsView = observer(() => {
    const { isNavOpen } = AppStore;
    const [modalPurpose, setModalPurpose] = useState(
        false
    );
    const {loading} = ProductsTransactionsStore
    const [filterModal, setFilterModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('BUSINESS STOCKS', 'TABLE HEADERS', controller.signal);
        ProductsTransactionsStore.getProducts(controller.signal);

        return () => controller.abort();
    }, [destroyDictionary, dictionaryListReq, ProductsTransactionsStore.getProducts]);

    const prevPage = () => {
        if (ProductsTransactionsStore.page > 1) {
            ProductsTransactionsStore.changePage(ProductsTransactionsStore.page - 1);
        }
    };

    const nextPage = () => {
        if (ProductsTransactionsStore.page < ProductsTransactionsStore.pages) {
            ProductsTransactionsStore.changePage(ProductsTransactionsStore.page + 1);
        }
    };

    const handleChangeFilters: React.ComponentProps<"input">["onChange"] = (
        event
    ) => {
        const { name, value } = event.target;
        ProductsTransactionsStore.changeFilter(name as keyof typeof ProductsTransactionsStore.filter, value);
    };

    const handleSubmit: React.ComponentProps<"form">["onSubmit"] = (event) => {
        event.preventDefault();
        ProductsTransactionsStore.getProducts();
    };

    const handleClearFilter = () => {
        ProductsTransactionsStore.clearFilter();
        setFilterModal(false);
    }

    const handleConfirmFilter = () => {
        setFilterModal(false);
    }

    const pageLimits = [25, 50, 100, 500];

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <>
                <Headline color={primaryColor}>{getPageTitle('BUSINESS STOCKS')}</Headline>
            <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                <TableHeader>
                    <Filters hAlign="space-between" wrapped between={10}>
                        <SearchLayout>
                            <form onSubmit={handleSubmit}>
                                <Search
                                    type="search"
                                    id="search"
                                    name="search"
                                    placeholder="Поиск по товарам"
                                    onChange={handleChangeFilters}
                                    value={ProductsTransactionsStore.filter.search}
                                ></Search>
                            </form>
                            <Image src={SearchIcon} height="16px" />
                        </SearchLayout>
                    </Filters>
                    <FilterButton
                        onClick={() => setFilterModal(true)}
                    >
                        Фильтры
                    </FilterButton>
                </TableHeader>
                <div>
                    <OperationView />
                </div>
            </div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>{getTableHeaders('name')}</THeadCell>
                                <THeadCell>{getTableHeaders('part_number')}</THeadCell>
                                <THeadCell>{getTableHeaders('brand')}</THeadCell>
                                <THeadCell>{getTableHeaders('category')}</THeadCell>
                                <THeadCell>{getTableHeaders('set_price')}</THeadCell>
                                <THeadCell>{getTableHeaders('quantity')}</THeadCell>
                                <THeadCell>{getTableHeaders('measure_unit')}</THeadCell>
                            </TRow>
                        </TableHead>
                        {!loading ? (
                        <TableBody>
                            {ProductsTransactionsStore.stocks!.length ? (
                                ProductsTransactionsStore.stocks?.map((product, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{product.name}</TCell>
                                        <TCell>{product.part_number}</TCell>
                                        <TCell>{product.brand}</TCell>
                                        <TCell>{product.category}</TCell>
                                        <TCell>{product.set_price}</TCell>
                                        <TCell>{product.quantity}</TCell>
                                        <TCell>{product.measure_unit}</TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={8} />
                            )}
                        </TableBody>
                        ) : (
                            <TableSkeleton columns={8}/>
                        ) }
                    </Table>
                </TableContainer>
                <TableContainerMobile>
                    <ProductsTransactionTableMobileView
                        loading={loading}
                        stocks={ProductsTransactionsStore.stocks}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>
                <TablePaginationView
                    prevPage={prevPage}
                    nextPage={nextPage}
                    pages={ProductsTransactionsStore.pages}
                    currentPage={ProductsTransactionsStore.page}
                    pageLimits={pageLimits}
                    handleFilterChange={ProductsTransactionsStore.changeFilter}
                />
            </>
            {filterModal && (
                <Modal
                    title={"Фильтры"}
                    onClose={() => setFilterModal(false)}
                >
                    <Layout vertical between={10}>
                        <SearchLayout>
                            <form onSubmit={handleSubmit}>
                                <Search
                                    type="search"
                                    id="search"
                                    name="search"
                                    placeholder="Поиск по товарам"
                                    onChange={handleChangeFilters}
                                    value={ProductsTransactionsStore.filter.search}
                                ></Search>
                            </form>
                            <Image src={SearchIcon} height="16px" />
                        </SearchLayout>
                        <Layout hAlign={"space-between"} between={10}>
                            <Button
                                extent={"L"}
                                id={"clear-filter-button"}
                                onClick={handleClearFilter}
                                background={"#333"}
                                color={"red"}
                            > Очистить </Button>
                            <Button
                                extent={"L"}
                                id={"confirm-filter-button"}
                                onClick={handleConfirmFilter}
                            > Потвердить </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
            {modalPurpose && (
                <Modal
                    title={ProductsTransactionsStore.product_title}
                    onClose={() => {
                        setModalPurpose(false);
                    }}>
                    <ProductsTransactionsModalView
                        closeModal={() => setModalPurpose(false)}
                    />
                </Modal>
            )}
        </Page >
    );
});