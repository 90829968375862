import {observer} from 'mobx-react';
import SettingsCreateEditStore from './settings.create.edit.store';
import {Layout} from '../../../styles/layout';
import {useEffect} from 'react';
import {renderInputs} from '../../form/render.inputs';
import {Button} from '../../../styles/button';

interface SettingsCreateEditProps {
    setModal: (isOpen: boolean) => void;
}

const SettingsCreateEditView = observer(
    ({setModal}: SettingsCreateEditProps) => {
        const {isLoading, updateBusinessReq} = SettingsCreateEditStore;

        useEffect(() => {
            SettingsCreateEditStore.businessReq();
        }, []);

        const {form} = SettingsCreateEditStore;

        const inputs = [
            {
                field: form.name,
                fieldName: 'name',
                placeholder: 'Название компании',
                type: 'text',
                required: true,
            },
            {
                field: form.phone,
                fieldName: 'phone',
                placeholder: 'Номер телефона',
                type: 'number',
                required: true,
            },
            {
                field: form.employee_bonus,
                fieldName: 'employee_bonus',
                placeholder: 'Бонусная система',
                type: 'switch',
            },
            {
                field: form.transaction_approval,
                fieldName: 'transaction_approval',
                placeholder: 'Подтверждение транзакции',
                type: 'switch',
            },
            {
                field: form.cheque_double_record,
                fieldName: 'cheque_double_record',
                placeholder: 'Двойная запись чека',
                type: 'switch',
            },
            {
                field: form.product_commissions,
                fieldName: 'product_commissions',
                placeholder: 'Комиссия продуктов',
                type: 'switch',
            },
            {
                field: form.product_reverse_commission,
                fieldName: 'product_reverse_commission',
                placeholder: 'Обратная комиссия во время операций',
                type: 'switch',
            },
            {
                field: form.working_hours,
                fieldName: 'working_hours',
                placeholder: 'Рабочее время',
                type: 'switch',
            },
        ];
        const renderSubmitText = 'Изменить';
        const handleChange = (object: any) => {
            SettingsCreateEditStore.changeForm(object);
        };

        const handleUpdateOwner = async () => {
            await updateBusinessReq();
            setModal(false);
        };
        return (
            <Layout vertical between={15}>
                <Layout vertical between={10}>
                    {renderInputs(form, inputs, handleChange)}
                    <Layout hAlign="center">
                        <Button
                            extent="S"
                            heightSize="35px"
                            isLoading={isLoading}
                            onClick={handleUpdateOwner}
                        >
                            {isLoading ? '' : renderSubmitText}
                        </Button>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
);

export default SettingsCreateEditView;
