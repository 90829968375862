import {observer} from "mobx-react";
import {ContextMenu, ContextMenuItem, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import {Layout} from "../../styles/layout";
import {Image} from "../../styles/image";
import ContextMenuIcon from "../../assets/icons/context-menu.svg";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import StatusesStore from "./statuses.store";
import {StatusModel} from "./statuses.model";
import {Badge} from "../../styles/badge";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface StatusesTableViewProps {
    handleReferencesClick: (status: StatusModel) => void;
    handleEditClick: (status: StatusModel) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    handleContext: (e: any) => void;
    anchorEl: any;
    reference: boolean;
    tableHeaders: (key: string) => string;
}

const StatusesTableDesktopView = observer(({
                                               handleReferencesClick,
                                               handleEditClick,
                                               setConfirmDeleteModal,
                                               handleContext,
                                               anchorEl,
                                               reference,
                                               tableHeaders
                                           }: StatusesTableViewProps) => {

    const {pageLoading, statuses, statusesAfter, statusesAfterLoading} = StatusesStore;
    const handleStatuses = () => {
        return reference ? statusesAfter : statuses
    }

    const handleLoading = () => {
        return reference ? statusesAfterLoading : pageLoading
    }

    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    <THeadCell>{tableHeaders('description')}</THeadCell>
                    <THeadCell>{tableHeaders('name')}</THeadCell>
                    <THeadCell>{tableHeaders('color')}</THeadCell>
                    <THeadCell>{tableHeaders('can_update')}</THeadCell>
                    <THeadCell>{tableHeaders('starting_status')}</THeadCell>
                    <THeadCell>{tableHeaders('can_execute')}</THeadCell>
                    {!reference && <THeadCell></THeadCell>}
                </TRow>
            </TableHead>
            {handleLoading() ? (<TableSkeleton columns={reference ? 5 : 6}/>) : (
                <TableBody>
                    {handleStatuses()?.length ? (
                        handleStatuses()!.map((status, index) => (
                            <TRow key={index} menu>
                                <TCell>{status.description}</TCell>
                                <TCell>{status.name}</TCell>
                                <TCell>
                                    <Layout style={{
                                        backgroundColor: status.color,
                                        height: '18px',
                                        width: '18px'
                                    }}/>
                                </TCell>
                                <TCell>
                                    <Badge online={status.can_update}>
                                        {status.can_update ? "Да" : "Нет"}
                                    </Badge>
                                </TCell>
                                <TCell>
                                    <Badge online={status.starting_status}>
                                        {status.starting_status ? "Да" : "Нет"}
                                    </Badge>
                                </TCell>
                                <TCell>
                                    <Badge online={status.can_execute}>
                                        {status.can_execute ? "Да" : "Нет"}
                                    </Badge>
                                </TCell>
                                {!reference && (
                                    <TCell relative>
                                        <Image
                                            src={ContextMenuIcon}
                                            height="18px"
                                            onClick={handleContext}
                                        />
                                        <ContextMenu anchorEl={anchorEl}>
                                            <ContextMenuItem onClick={() => handleReferencesClick(status)}>
                                                Статусы после
                                            </ContextMenuItem>
                                            <ContextMenuItem onClick={() => handleEditClick(status)}>
                                                Изменить
                                            </ContextMenuItem>
                                            <ContextMenuItem
                                                onClick={() =>
                                                    setConfirmDeleteModal({
                                                        isModalOpen: true,
                                                        id: status.id,
                                                        name: status.description,
                                                    })
                                                }
                                            >
                                                Удалить
                                            </ContextMenuItem>
                                        </ContextMenu>
                                    </TCell>
                                )}
                            </TRow>
                        ))
                    ) : (<NoResultTitle colspan={reference ? 5 : 6}/>)}
                </TableBody>
            )}
        </Table>
    );
});

export default StatusesTableDesktopView;