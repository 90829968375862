import {TableCellSkeleton} from "./skeleton.style";
import {TableBody, TCell, TRow} from "../../styles/table";

interface TableSkeletonProps {
    columns: number;
}

const TableSkeleton = (({columns}: TableSkeletonProps) => {
    const renderedCells = [...Array(columns)].map(() => (
        <TCell style={{
            overflow: "hidden",
            padding: "8px 8px"
        }}>
            <TableCellSkeleton>
                <div className={"cell-skeleton"}></div>
            </TableCellSkeleton>
        </TCell>
    ));

    return (
      <TableBody>
          <TRow>{renderedCells}</TRow>
          <TRow>{renderedCells}</TRow>
          <TRow>{renderedCells}</TRow>
          <TRow>{renderedCells}</TRow>
          <TRow>{renderedCells}</TRow>
          <TRow>{renderedCells}</TRow>
          <TRow>{renderedCells}</TRow>
          <TRow>{renderedCells}</TRow>
          <TRow>{renderedCells}</TRow>
          <TRow>{renderedCells}</TRow>
      </TableBody>
    );
});

export default TableSkeleton;