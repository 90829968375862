import {StatusModel} from './statuses.model'
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import {toast} from "react-toastify";

const statusesInitialState: StatusModel[] = [];
const loadingInitialState: boolean = false;
const statusesAfterInitialState: StatusModel[] = [];

class StatusesStore {
    public pageLoading = loadingInitialState;
    public statusesAfterLoading = loadingInitialState;
    public statuses? = statusesInitialState;
    public statusesAfter = statusesAfterInitialState;
    public message: string = '';
    public error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public statusesPageInit = async (technical: boolean, signal?: AbortSignal) => {
        await this.statusesReq(technical, signal);
    }

    setStatuses(data: any) {
        const {statuses} = data;
        this.statuses = statuses;
    }

    setStatusesAfter(data: any) {
        const {statuses} = data;
        this.statusesAfter = statuses;
    }

    setLoading(loading: boolean) {
        this.pageLoading = loading;
    }

    setStatusesAfterLoading(loading: boolean) {
        this.statusesAfterLoading = loading;
    }

    public destroy = () => {
        this.statuses = statusesInitialState;
        this.statusesAfter = statusesAfterInitialState;
        this.pageLoading = loadingInitialState;
        this.statusesAfterLoading = loadingInitialState;
    }

    public statusesReq = async (technical: boolean, signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.get(api.statuses + `?technical=${technical}`, {headers, signal});
            this.setStatuses(response.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public deleteStatusReq = async (id: number) => {
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.delete(api.statuses + `/${id}`, {headers});
            this.message = response.data.message;
            toast.success(this.message);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        }

        await this.statusesReq(false);
    }

    public statusesAfterReq = async (id: number) => {
        this.setStatusesAfterLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.get(api.statuses + `/${id}`, {headers});
            this.setStatusesAfter(response.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setStatusesAfterLoading(false);
        }
    }
}

export default new StatusesStore();