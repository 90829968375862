import { observer } from 'mobx-react';
import { Text } from '../../styles/text';
import { Image } from '../../styles/image';
import { primaryColor } from '../../utils/variables';

import Xmark from '../../assets/icons/xmark.svg';
import {
  AccountsModalInner,
  ModalBody,
  ModalContainer,
  ModalGlobalStyles,
  ModalHeader,
} from './modal.style';

interface ModalProps {
  children: JSX.Element;
  title?: string;
  onClose: () => void;
  hasContext?: boolean;
  fullScreen?: boolean;
}

const AccountModalView = observer(
  ({ children, title, onClose, hasContext, fullScreen }: ModalProps) => {
    return (
      <ModalContainer onClick={onClose} hasContext={hasContext}>
        <AccountsModalInner
          onClick={(e) => e.stopPropagation()}
          hasContext={hasContext}
          fullScreen={fullScreen}
        >
          <ModalHeader>
            <Text extent="20px" weight="700" color={primaryColor}>
              {title}
            </Text>
            <Image src={Xmark} extent="18px" onClick={onClose} />
          </ModalHeader>
          <ModalBody fullScreen={fullScreen}>{children}</ModalBody>
        </AccountsModalInner>
        <ModalGlobalStyles />
      </ModalContainer>
    );
  }
);

export default AccountModalView;
