import axios from 'axios';
import {makeAutoObservable} from 'mobx';
import { api } from '../../../api/endpoints';
import AppStore from '../../../app.store';
import { ClientsModel } from '../clients.model';
import { toast } from 'react-toastify';

const initialValues = {
    active: true,
    email: '',
    login: '',
    name: '',
    password: '',
    patronymic: '',
    phone: '',
    surname: '',
    business_unit_id: 0,
    role_id: 0,
    id: 0,
    business_unit: '',
    full_name: '',
    inn: '',
    passport_number: '',
    passport_series: '',
    address: '',
};

class ClientsCreateEditStore {
    public form: ClientsModel = initialValues;
    public error = '';
    public message = '';
    public isLoading = false;
    
    constructor() {
        makeAutoObservable(this);
    }

    public init = async (initialValue: ClientsModel | null) => {
        if (initialValue) {
            this.form = initialValue;
        } else {
            this.form = initialValues;
        }
    }

    public createOrEditClient = async (edit?: boolean) => {
        this.isLoading = true;
        const payload = {
            passport_number: this.form.passport_number,
            passport_series: this.form.passport_series,
            address: this.form.address,
            inn: this.form.inn,
            id: this.form.id,
            login: this.form.login,
            name: this.form.name,
            patronymic: this.form.patronymic,
            phone: this.form.phone,
            surname: this.form.surname
        }
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            let res;
            if (edit) {
                res = await axios.put(api.client, payload, {headers});
            } else {
                res = await axios.post(api.client, payload, {headers});
            }
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = res?.data.message;
            toast.success(this.message);

            return res;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.isLoading = false;
        }
    }

    public setField = (form: ClientsModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
    }

}

export default new ClientsCreateEditStore();