import {observer} from "mobx-react";
import StatusesStore from "./statuses.store";
import {
    EyeImgIcon,
    Table,
    TableBody,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../styles/table";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import {Layout} from "../../styles/layout";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import {useState} from "react";
import {Text} from "../../styles/text";
import {Button} from "../../styles/button";
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import ModalView from "../../components/modal/modal.view";
import {StatusModel} from "./statuses.model";
import {Badge} from "../../styles/badge";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface StatusesTableViewProps {
    handleReferencesClick: (status: StatusModel) => void;
    handleEditClick: (status: StatusModel) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    reference: boolean;
    tableHeaders: (key: string) => string;
}

const StatusesTableMobileView = observer(({
                                              handleReferencesClick,
                                              handleEditClick,
                                              setConfirmDeleteModal,
                                              reference,
                                              tableHeaders
                                          }: StatusesTableViewProps) => {
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<StatusModel | null>(null);
    const {pageLoading, statuses, statusesAfter, statusesAfterLoading} = StatusesStore;

    const handleRowClick = (status: StatusModel) => {
        setModal(true);
        setInitialValue(status);
    };

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    };

    const renderModalItems = (status: StatusModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('description')}</Text>
                <Text>{status.description}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('name')}</Text>
                <Text>{status.name}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('color')}</Text>
                <Text>
                    <Layout style={{
                        backgroundColor: status.color,
                        height: '18px',
                        width: '18px'
                    }}/>
                </Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('can_update')}</Text>
                <Text>
                    <Badge online={status.can_update}>
                        {status.can_update ? "Да" : "Нет"}
                    </Badge>
                </Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('starting_status')}</Text>
                <Text>
                    <Badge online={status.starting_status}>
                        {status.starting_status ? "Да" : "Нет"}
                    </Badge>
                </Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('can_execute')}</Text>
                <Text>
                    <Badge online={status.can_execute}>
                        {status.can_execute ? "Да" : "Нет"}
                    </Badge>
                </Text>
            </Layout>
            {!reference && (
                <Layout hAlign={'center'} between={5}>
                    <Button onClick={() => handleReferencesClick(status)}>Статусы после</Button>
                    <Button onClick={() => handleEditClick(status)}>Изменить</Button>
                    <Button
                        background={'rgb(146,4,4)'}
                        onClick={() =>
                            setConfirmDeleteModal({
                                isModalOpen: true,
                                id: status.id,
                                name: status.description,
                            })
                        }
                    >
                        Удалить
                    </Button>
                </Layout>
            )}
        </Layout>
    );

    const handleStatuses = () => {
        return reference ? statusesAfter : statuses
    }

    const handleLoading = () => {
        return reference ? statusesAfterLoading : pageLoading
    }

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('description')}</THeadCell>
                        <THeadCell>{tableHeaders('name')}</THeadCell>
                        <THeadCell>{tableHeaders('color')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!handleLoading() ? (
                    <TableBody>
                        {handleStatuses()?.length ? (
                            handleStatuses()?.map((status, index) => (
                                <TRow key={index} menu>
                                    <TCell>{status.description}</TCell>
                                    <TCell>{status.name}</TCell>
                                    <TCell>
                                        <Layout widthSize={'18px'} background={status.color}/>
                                    </TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(status)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные статуса ${initialValue?.description}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return <Layout>{renderedItems()}</Layout>;
});

export default StatusesTableMobileView;