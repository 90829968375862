import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {Search, SearchLayout, Select} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import {textColorDark} from "../../utils/variables";

interface FiltersProps {
    filterInputs: any[];
    isInModal: boolean;
    handleFilterChange?: (e: any, key: string) => void
    setDates?: (e: any) => void;
    handleKeyDown?: (e: any) => void;
    cancelSearch?: (e: any) => void;
    filters: any;
}

const FilterView = observer(({
                                 filterInputs,
                                 handleFilterChange,
                                 setDates,
                                 isInModal,
                                 handleKeyDown,
                                 cancelSearch,
                                 filters
                             }: FiltersProps) => {

    const {RangePicker} = DatePicker;
    const today = new Date;
    const month = today.getMonth() < 10 ? `0${today.getMonth()}` : `${today.getMonth()}`
    const currentDate = `${today.getFullYear()}-${month}-${today.getDate()}`

    const dateFrom = () => {
        let date: string = '';
        if (filters.date_from === '') {
            date = currentDate;
        } else {
            date = filters.date_from;
        }
        return date;
    }

    const dateTo = () => {
        let date: string = '';
        if (filters.date_to === '') {
            date = currentDate;
        } else {
            date = filters.date_to;
        }
        return date;
    }

    const dateFromJS = dayjs(dateFrom(), 'YYYY-MM-DD');
    const dateToJS = dayjs(dateTo(), 'YYYY-MM-DD');

    return (
        <Layout between={10} wrapped vAlign="center" vertical={isInModal}>
            {filterInputs.map((input, index) => {
                const {fieldName, placeholder, type, options, defaultValue} = input;

                switch (type) {
                    case 'search':
                        return (
                            <SearchLayout
                                key={index}
                            >
                                <Search
                                    type="search"
                                    id="search"
                                    placeholder={`Поиск по ${placeholder.toString().toLowerCase()}`}
                                    onKeyDown={(e) => handleKeyDown?.(e)}
                                    onChange={(e) => cancelSearch?.(e)}
                                    value={filters[fieldName]}
                                />
                                <Image src={SearchIcon} height="16px"/>
                            </SearchLayout>
                        );
                    case 'select':
                        return (
                            <Select
                                id={`${fieldName}-filter`}
                                style={{width: 150}}
                                onChange={(e) => handleFilterChange?.(e, fieldName)}
                                key={index}
                                value={filters[fieldName]}
                                defaultValue={defaultValue}
                            >
                                {placeholder && <option value="">{placeholder}</option>}
                                {options?.map((option: any, index: number) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                        );
                    case 'range_picker':
                        return (
                            <RangePicker
                                onChange={(e) => {
                                    setDates?.(e);
                                }}
                                placeholder={['Начало даты', 'Конец даты']}
                                style={{
                                    height: 40,
                                    width: 250,
                                    borderRadius: 12,
                                    border: 0,
                                    borderWidth: 1,
                                    borderColor: '#E5E5E5',
                                    color: '#374151',
                                    backgroundColor: '#FFFFFF',
                                }}
                                key={index}
                                popupStyle={{
                                    fontFamily: 'Open-Sans, Helvetica, Sans-Serif',
                                    color: textColorDark,
                                }}
                            //    defaultValue={[dateFromJS, dateToJS]}
                            />
                        );
                    default:
                        return null
                }
            })}
        </Layout>
    );
});

export default FilterView;