import { Form, ServiceModel, StockModel } from './create.operation.model';
import AppStore from '../../../app.store';
import axios from 'axios';
import { api } from '../../../api/endpoints';
import { makeAutoObservable } from 'mobx';
import TransactionsStore from '../../transactions/transactions.store';
import { toast } from 'react-toastify';
import Business_unitsStore from '../../business_units/business_units.store';
import BusinessUnitsStore from '../../business_units/business_units.store';
import ProductsAllSellStore from '../products_all_sell.modal/products_all_sell.modal.store';
import ClientsStore from '../../clients/clients.store';
import AccountStore from '../../accounts/account.store';
import transactionsStore from '../../transactions/transactions.store';
import { TransactionsModel } from '../../transactions/transactions.model';
const formInitialValue: Form = {
  business_unit_id: 0,
  product_id: 0,
  amount: 0,
  quantity: 0,
  client: 0,
  clients: [],
  recomdended_price: 0,
  set_price: 0,
  unit: [],
  company: '',
  employee_id: 0,
  //
  paying_client_phone: '',
  paying_client_full_name: '',
  paying_client_date_of_birth: '',
  paying_client_passport_number: '',
  paying_client_passport_issuing_authority: '',
  paying_client_passport_date_of_issue: '',
  paying_client_passport_date_of_expiry: '',

  //
  id: 0,
  bu_id: 0,
  total_amount: 0,
  client_id: 1,
  account_id: 1,
  payment_method_id: 1,
  details: '',
  account: '',
  type_id: 1,
  products: [],
};

class CreateOperationStore {
  public isLoading = false;
  public stockLoading = false;
  public serviceLoading = false;
  public stocks: StockModel[] = [];
  public services: ServiceModel[] = [];
  public search? = '';
  public form: Form = formInitialValue;
  public error = '';
  public message = '';
  public selectedProducts = [];
  public products = [];
  public selectedProduct: any = { goods_id: '' };
  public serviceAccounts = [];
  transactions = TransactionsStore;
  public businessUnits = BusinessUnitsStore;
  public paymentMethods = ProductsAllSellStore;
  public transactionGoods = transactionsStore;
  public transactionServices = transactionsStore;
  public clients = ClientsStore;
  public accounts = AccountStore;

  constructor() {
    makeAutoObservable(this);
  }

  public destroy = () => {
    this.form = formInitialValue;
    this.businessUnits.businessUnits = [];
    this.paymentMethods.paymentMethods = [];
    this.accounts.accounts = [];
    this.search = '';
    this.stocks = [];
    this.services = [];
    this.selectedProduct = { goods_id: '' };
    this.selectedProducts = [];
  };

  public setProduct(product: any) {
    this.selectedProduct = product;
  }

  public setField = (form: Form) => {
    this.form = form;
  }

  public changeForm = (name: string, value: string | number) => {
    // @ts-ignore
    this.form[name as keyof Form] = value;
  };

  public setProductField = (product: any, value: any, name: any) => {
    product[name] = value;
    //product.totalSumm = product.summ * product.set_price;
  };

  public setProductTotalSumm = (product: any, value: any) => {
    product.totalSumm = value;
  };

  public selectSubmit = (product: any) => {
    // @ts-ignore
    this.selectedProducts.push(product);
  };

  public deleteProduct = (id: any) => {
    this.selectedProducts = this.selectedProducts.filter(
      (item: any) => item.id !== id
    );
  };

  public setSearch = (
    search: string,
    type: string,
    businessUnitID?: number
  ) => {
    const controller = new AbortController();
    this.search = search;
    if (type === 'stock') {
      void this.stocksReq(controller.signal, businessUnitID);
    } else if (type === 'service') {
      void this.servicesReq(controller.signal);
    }
  };

  setStocks(data: any) {
    const { stocks } = data;
    this.stocks = stocks;
  }

  setServices(data: any) {
    const { services } = data;
    this.services = services;
  }

  public stocksReq = async (signal?: AbortSignal, businessUnitID?: number) => {
    this.stockLoading = true;

    const { getToken } = AppStore;
    const headers = {
      Authorization: 'Bearer ' + getToken()!,
    };

    const url =
      businessUnitID === 0 || businessUnitID === undefined
        ? api.get_business_goods
        : api.business_stocks;

    const businessUnitFilter = () => {
      if (businessUnitID !== undefined && businessUnitID !== 0) {
        return `&business_unit_id=${businessUnitID}`;
      }
      return '';
    };

    const searchFilter = () => {
      if (this.search !== '' && this.search !== undefined) {
        return `&search=${this.search}`;
      }
      return '';
    };

    const filters = `?page=1&page_limit=999999${businessUnitFilter()}${searchFilter()}`;

    try {
      const response = await axios.get(url + filters, { headers, signal });
      this.setStocks(response.data);
    } catch (e) {
    } finally {
      this.stockLoading = false;
    }
  };

  public servicesReq = async (signal?: AbortSignal) => {
    this.serviceLoading = true;

    const { getToken, userData } = AppStore;
    const { businessUnits, businessUnitsReq } = Business_unitsStore;
    void businessUnitsReq(signal);
    const headers = {
      Authorization: 'Bearer ' + getToken()!,
    };

    const businessUnitFilter = () => {
      if (userData?.role_id !== 1) {
        return `&business_unit_id=${userData?.business_unit_id}`;
      } else {
        return `&business_unit_id=${businessUnits![0].id}`;
      }
    };

    const searchFilter = () => {
      if (this.search !== '' && this.search !== undefined) {
        return `&search=${this.search}`;
      }
      return '';
    };

    const filters = `?page=1&page_limit=999999${businessUnitFilter()}${searchFilter()}`;

    try {
      const response = await axios.get(api.business_service + filters, {
        headers,
        signal,
      });
      this.setServices(response.data);
    } catch (e) {
    } finally {
      this.serviceLoading = false;
    }
  };

  public serviceAccountsReq = async (client_id: number, service_id: number) => {
    const headers = {
      Authorization: 'Bearer ' + AppStore.getToken(),
    };
    try {
      const response = await axios.get(
        api.transactions_service_accounts +
          '?client_id=' +
          client_id +
          '&service_id=' +
          service_id,
        { headers }
      );
      const { accounts } = response.data;
      this.serviceAccounts = accounts;
    } catch (error) {}
  };
  public handleSelectProduct = (object: any, type: string) => {
    object.summ = object.quantity;
    object.set_price = object.price;
    object.product_id = object.id;
    object.totalSumm = object.total;
    object.type = type;
    this.selectSubmit(object);
  };

  public tansactionProducts = () => {
    this.transactionServices.transactionServices?.forEach((item) =>
      this.handleSelectProduct(item, 'service')
    );
    this.transactionGoods.transactionGoods?.forEach((item) =>
      this.handleSelectProduct(item, 'goods')
    );
  };
  public init = async (initialValue: TransactionsModel | null) => {
    const { transactionProductsReq } = transactionsStore;
    await transactionProductsReq(initialValue?.id!);

    if (initialValue) {
      this.form.id = initialValue.id;
      this.form.bu_id = initialValue?.business_unit_id!;
      this.form.client_id = initialValue.client_id!;
      this.form.payment_method_id = initialValue.payment_method_id!;
      this.form.details = initialValue.details;
      this.form.account = initialValue.account!;
      this.tansactionProducts();
    }
  };

  public createOperationReq = async (edit?: boolean) => {
    const { getToken } = AppStore;
    const headers = {
      Authorization: 'Bearer ' + getToken(),
    };

    const payload = {
      id: Number(this.form.id),
      bu_id: Number(this.form.bu_id),
      client_id: Number(this.form.client_id),
      account_id: Number(this.form.account_id),
      total_amount: Number(this.form.total_amount),
      details: this.form.details,
      payment_method_id: Number(this.form.payment_method_id),
      account: this.form.account,
      type_id: Number(this.form.type_id),
      employee_id: Number(this.form.employee_id),
      //
      paying_client_phone: this.form.paying_client_phone,
      paying_client_full_name: this.form.paying_client_full_name,
      paying_client_date_of_birth: this.form.paying_client_date_of_birth,
      paying_client_passport_number: this.form.paying_client_passport_number,
      paying_client_passport_issuing_authority: this.form.paying_client_passport_issuing_authority,
      paying_client_passport_date_of_issue: this.form.paying_client_passport_date_of_issue,
      paying_client_passport_date_of_expiry: this.form.paying_client_passport_date_of_expiry,
      //
      products: [],
    };

    const products = this.selectedProducts.map((item: any) => ({
      account: item.account,
      amount: Number(item.quantity),
      product_id: item.id,
      quantity: Number(item.quantity),
      total_summ: item.total_amount,
      expiry_id: Number(item.expiry_id),
      commission_from_amount: Boolean(item.commission_from_amount),
    }));

    // @ts-ignore
    payload.products = products;

    this.isLoading = true;
    try {
      let response;
      if (edit) {
        response = await axios.put(api.transactions, payload, {
          headers,
        });
      } else {
        response = await axios.post(api.transactions, payload, {
          headers,
        });
      }

      this.message = response.data.message;
      toast.success(this.message);

      return response;
    } catch (error: any) {
      this.error = error?.response.data.reason;
      toast.error(this.error);
    } finally {
      this.isLoading = false;
      void this.transactions.transactionsReq();
    }
  };
}

export default new CreateOperationStore();
