import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import ClientsCreateEditStore from './clients.create.edit.store';
import ClientsStore from '../clients.store';
import {useEffect} from "react";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import {ClientsModel} from "../clients.model";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface ClientsCreateEditViewProps {
    initialValue: ClientsModel | null;
    setModal: (isOpen: boolean) => void;
}

const ClientsCreateEditView = observer(({initialValue, setModal}: ClientsCreateEditViewProps) => {
    const {
        form,
        setField,
        init,
        createOrEditClient,
        isLoading,
        destroy
    } = ClientsCreateEditStore;
    const {
        name,
        surname,
        patronymic,
        inn,
        passport_number,
        passport_series,
        address,
        phone,
    } = form;
    const {clientsReq} = ClientsStore;
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        dictionaryListReq('CLIENTS', 'MODAL', controller.signal);
        init(initialValue);

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);

    const inputs = [
        {field: name, fieldName: 'name', placeholder: getModalPlaceholders('name'), type: 'text', required: true},
        {field: surname, fieldName: 'surname', placeholder: getModalPlaceholders('surname'), type: 'text', required: false},
        {field: patronymic, fieldName: 'patronymic', placeholder: getModalPlaceholders('patronymic'), type: 'text', required: false},
        {field: phone, fieldName: 'phone', placeholder: getModalPlaceholders('phone'), type: 'text', required: true},
        {field: address, fieldName: 'address', placeholder: getModalPlaceholders('address'), type: 'text', required: false},
        {field: inn, fieldName: 'inn', placeholder: getModalPlaceholders('inn'), type: 'text', required: false},
        {field: passport_series, fieldName: 'passport_series', placeholder: getModalPlaceholders('passport_series'), type: 'text', required: false},
        {field: passport_number, fieldName: 'passport_number', placeholder: getModalPlaceholders('passport_number'), type: 'text', required: false},
    ];

    const handleCreateEditClient = async () => {
        const res = await createOrEditClient(!!initialValue);
        if (res) {
            setModal(false);
            await clientsReq();
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditClient}
                    disabled={isValid([inputs])}
                >{isLoading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default ClientsCreateEditView;
